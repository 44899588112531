<template>
  <div class="tags-advanced-search">
    <PageTitle
      icon="chevron_left"
      title="標籤設定列表 進階搜尋"
      hideBtn
      style="margin-bottom: 24px"
      @iconClick="$router.go(-1)"
    />
    <div v-loading="reqEstimate.isLoading.value">
      <ConditionForm
        :tags="allTags"
        :group="group"
        :total="total"
        :showCount.sync="showCount"
        @submit="onSubmit"
        @message="onMessage"
      />
    </div>
  </div>
</template>

<script>
import ConditionForm from './components/ConditionForm.vue'
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { useTagStore } from './components/useTagStore'
import { Estimate } from '@/api/pushMessage'
import { useAsyncState, useStorage } from '@vueuse/core'
import { KEY_MEMBER_TAG_PUSH_MESSAGE } from '@/utils/localstorage'
export default defineComponent({
  name: 'TagsAdvancedSearch',
  components: {
    ConditionForm,
  },
  setup () {
    const { $message, $store, $router } = getCurrentInstance().proxy
    const shopId = computed(() => $store.getters.shop)

    onMounted(async () => {
      const [, err] = await getAllTags()
      if (err) {
        $message.error(err || err.message)
      }
    })

    const { tags: allTags, getAllTags } = useTagStore()

    const reqEstimate = useAsyncState(
      async (conditionsGroup = []) => {
        const group = await conditionsGroup.reduce(async (prev, group) => {
          let data
          if (prev) data = await prev

          const count = await Estimate({
            shopId: shopId.value,
            object: 'member',
            conditions: [group],
          })
          data.push(count)
          return Promise.resolve(data)
        }, Promise.resolve([]))

        const total = await Estimate({
          shopId: shopId.value,
          object: 'member',
          conditions: conditionsGroup,
        })
        return { group, total }
      }, {}, { immediate: false })

    const group = ref([])
    const total = ref({})
    const showCount = ref(false)

    let savedConditions
    const onSubmit = async (payload) => {
      savedConditions = payload.map(i => ({
        includes: i.includes.map(j => j.id),
        excludes: i.excludes.map(j => j.id),
      }))
      const res = await reqEstimate.execute(0, savedConditions)
      if (reqEstimate.error.value) {
        showCount.value = false
        return $message.error(reqEstimate.error.value)
      }
      showCount.value = true
      group.value = res.group
      total.value = res.total
    }

    const pushMsgConditions = useStorage(
      KEY_MEMBER_TAG_PUSH_MESSAGE, [{
        includes: [],
        excludes: [],
      }],
      sessionStorage,
      { flush: 'sync' })
    const onMessage = async (payload) => {
      const savedConditions_ = payload.map(i => ({
        includes: i.includes.filter(j => j.memberCount > 0).map(j => j.id),
        excludes: i.excludes.filter(j => j.memberCount > 0).map(j => j.id),
      }))
      pushMsgConditions.value = savedConditions_
      $router.push({ name: 'PushMessage', query: { tagsAdvancedSearch: true } })
    }

    return {
      allTags,
      onSubmit,
      onMessage,
      group,
      total,
      showCount,
      reqEstimate,
    }
  },
})
</script>

<style lang="scss">
.tags-advanced-search {
}
</style>
