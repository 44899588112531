<template>
  <div class="input-condition-group">
    <el-card>
      <div slot="header">
        <div>
          <span class="text-primary-100 font-medium text-[18px] mr-3">
            條件{{ titleSuffix }}
          </span>
          <el-button v-if="showDelete" type="text" class="btn-delete" @click="$emit('delete')">刪除</el-button>
        </div>
        <div>
          <span v-if="isEmpty(group) || group.isAccurate">符合受眾數: {{ group.count }}</span>
          <span v-else>符合受眾數約 {{ group.count }} 人 ({{ group.countConfidenceMin }} ~ {{ group.countConfidenceMax }}人)</span>
        </div>
      </div>
      <div class="flex">
        <!-- ANCHOR 包含 -->
        <div class="tag-group">
          <div>
            <span class="font-bold mr-2 text-[16px]">包含</span>
            <MaterialIcon size="20" class="btn-edit" @click.native="$emit('edit:includes', includesValue)">edit_round</MaterialIcon>
          </div>
          <ul class="flex flex-wrap">
            <li v-for="item in includesValue" :key="item.id" class="tag-item">
              <Tag type="info" class="flex">
                <span class="truncate">
                  {{ item.name }}
                </span>
                <span>
                  ({{ item.memberCount }}位)
                </span>
              </Tag>
            </li>
          </ul>
          <div v-if="includesValue.length === 0" class="text-center">
            <span class="no-data">未選擇標籤，請點擊編輯鈕 (鉛筆圖示) 選取標籤</span>
          </div>
        </div>
        <!-- ANCHOR 不包含 -->
        <div class="tag-group group-exclude">
          <div>
            <span class="font-bold mr-2 text-[16px]">不包含</span>
            <MaterialIcon size="20" class="btn-edit" @click.native="$emit('edit:excludes', excludesValue)">edit_round</MaterialIcon>
          </div>
          <ul class="flex flex-wrap">
            <li v-for="item in excludesValue" :key="item.id" class="tag-item">
              <Tag type="info" class="flex">
                <span class="truncate">
                  {{ item.name }}
                </span>
                <span>
                  ({{ item.memberCount }}位)
                </span>
              </Tag>
            </li>
          </ul>
          <div v-if="excludesValue.length === 0" class="text-center">
            <span class="no-data">未選擇標籤，請點擊編輯鈕 (鉛筆圖示) 選取標籤</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { isEmpty } from 'lodash'
export default {
  name: 'InputConditionGroup',
  components: { Tag },
  props: {
    includesValue: { type: Array },
    excludesValue: { type: Array },
    showDelete: { type: Boolean, default: true },
    titleSuffix: { type: String, default: '' },
    group: { type: Object, default: () => ({}) },
  },
  emits: ['delete', 'edit:includes', 'edit:excludes'],
  methods: {
    isEmpty,
  },
}
</script>

<style lang="scss" scoped>
.input-condition-group {

  &.disabled {
    @apply opacity-30;
  }

  :deep(.el-card) {
    @apply p-3 rounded;
  }

  :deep(.el-card__header) {
    @apply p-0;
  }
  :deep(.el-card__body){
    @apply p-0;
  }
  .tag-group {
    @apply w-1/2;

    &.group-exclude {
      @apply border-l pl-[24px];
    }
  }
  .tag-item {
    @apply mr-[12px] mb-3;

    :deep(.el-tag) {
      @apply max-w-[200px];
    }
  }
  .no-data {
    @apply text-gray-50;
  }
  .btn-edit {
    @apply align-text-bottom cursor-pointer;
  }
  .btn-delete {
    @apply text-danger underline text-[12px];
  }
}
</style>
